exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-claim-js": () => import("./../../../src/pages/claim.js" /* webpackChunkName: "component---src-pages-claim-js" */),
  "component---src-pages-collective-grants-js": () => import("./../../../src/pages/collective-grants.js" /* webpackChunkName: "component---src-pages-collective-grants-js" */),
  "component---src-pages-collective-rewards-js": () => import("./../../../src/pages/collective-rewards.js" /* webpackChunkName: "component---src-pages-collective-rewards-js" */),
  "component---src-pages-get-rif-js": () => import("./../../../src/pages/get-rif.js" /* webpackChunkName: "component---src-pages-get-rif-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/Blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/FAQ.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-roadmap-js": () => import("./../../../src/templates/Roadmap.js" /* webpackChunkName: "component---src-templates-roadmap-js" */)
}

